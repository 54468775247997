<template>
	<UsersList />
</template>

<script>
import UsersList from './users-list/UsersList'
export default {
	name: 'Employees',
	components: { UsersList },
}
</script>