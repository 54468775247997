<template>
	<div>
		<user-list-add-new
			:is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
			:role-options="roleOptions"
			:plan-options="planOptions"
			@refetch-data="refetchData"
		/>

		<!-- Filters -->
		<users-list-filters
			:role-filter.sync="roleFilter"
			:plan-filter.sync="planFilter"
			:status-filter.sync="statusFilter"
			:role-options="roleOptions"
			:plan-options="planOptions"
			:status-options="statusOptions"
		/>

		<!-- Table Container Card -->
		<b-card no-body class="mb-0">
			<div class="m-2">
				<!-- Table Top -->
				<b-row>
					<!-- Per Page -->
					<b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
						<label>Zeige</label>
						<v-select
							v-model="perPage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label>Einträge</label>
					</b-col>

					<!-- Search -->
					<b-col cols="12" md="6">
						<div class="d-flex align-items-center justify-content-end">
							<b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Suche..." />
							<b-button variant="primary" @click="isAddNewUserSidebarActive = true">
								<span class="text-nowrap">Mitarbeiter hinzufügen</span>
							</b-button>
						</div>
					</b-col>
				</b-row>
			</div>

			<b-table
				ref="refUserListTable"
				class="position-relative table"
				:items="fetchUsers"
				responsive
				:fields="tableColumns"
				primary-key="_id"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="Keine passenden Mitarbeiter gefunden"
				:sort-desc.sync="isSortDirDesc"
			>
				<!-- Column: User -->
				<template #cell(user)="data">
					<div class="d-flex align-items-center justify-content-start">
						<b-avatar
							size="32"
							:src="data.item.avatar"
							:text="avatarText(`${data.item.firstname} ${data.item.lastname}`)"
							:variant="`light-${resolveUserRoleVariant(data.item.role)}`"
							:to="{ name: 'wercbrain-employees-edit', params: { id: data.item._id } }"
						/>
						<b-link
							:to="{ name: 'wercbrain-employees-edit', params: { id: data.item._id } }"
							class="ml-1 font-weight-bold d-block text-nowrap"
						>
							{{ data.item.firstname }} {{ data.item.lastname }}
						</b-link>
					</div>
				</template>

				<!-- Column: Role -->
				<template #cell(role)="data">
					<div class="text-nowrap">
						<feather-icon
							:icon="resolveUserRoleIcon(data.item.role)"
							size="18"
							class="mr-50"
							:class="`text-${resolveUserRoleVariant(data.item.role)}`"
						/>
						<span class="align-text-top text-capitalize">{{ findRoleLabel(data.item.role) }}</span>
					</div>
				</template>

				<!-- Column: Status -->
				<template #cell(status)="data">
					<b-badge pill :variant="`light-${resolveUserStatusVariant(data.item.status)}`" class="text-capitalize">
						{{ findStatusLabel(data.item.status) }}
					</b-badge>
				</template>

				<!-- Column: Actions -->
				<template #cell(actions)="data">
					<b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
						<template #button-content>
							<feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
						</template>
						<!-- <b-dropdown-item :to="{ name: 'wercbrain-employees-details', params: { id: data.item._id } }">
							<feather-icon icon="FileTextIcon" />
							<span class="align-middle ml-50">Details</span>
						</b-dropdown-item> -->

						<b-dropdown-item :to="{ name: 'wercbrain-employees-edit', params: { id: data.item._id } }">
							<feather-icon icon="EditIcon" />
							<span class="align-middle ml-50">Bearbeiten</span>
						</b-dropdown-item>

						<b-dropdown-item @click="() => deleteUser(data.item._id)">
							<feather-icon icon="TrashIcon" />
							<span class="align-middle ml-50">Löschen</span>
						</b-dropdown-item>
					</b-dropdown>
				</template>
			</b-table>
			<div class="mx-2 mb-2">
				<b-row>
					<b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
						<span class="text-muted">Zeige {{ dataMeta.from }} bis {{ dataMeta.to }} von {{ dataMeta.of }} Einträgen</span>
					</b-col>
					<!-- Pagination -->
					<b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
						<b-pagination
							v-model="currentPage"
							:total-rows="totalUsers"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon icon="ChevronLeftIcon" size="18" />
							</template>
							<template #next-text>
								<feather-icon icon="ChevronRightIcon" size="18" />
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>
	</div>
</template>

<script>
import {
	BCard,
	BRow,
	BCol,
	BFormInput,
	BButton,
	BTable,
	BMedia,
	BAvatar,
	BLink,
	BBadge,
	BDropdown,
	BDropdownItem,
	BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import UsersListFilters from './UsersListFilters.vue'
import useUsersList from './useUsersList'
import userStoreModule from '../userStoreModule'
import UserListAddNew from './UserListAddNew.vue'

export default {
	components: {
		UsersListFilters,
		UserListAddNew,

		BCard,
		BRow,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,

		vSelect,
	},
	setup() {
		// const USER_APP_STORE_MODULE_NAME = 'app-user'

		// // Register module
		// if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

		// // UnRegister on leave
		// onUnmounted(() => {
		// 	if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
		// })

		const isAddNewUserSidebarActive = ref(false)

		const roleOptions = [
			{ label: 'Mitarbeiter', value: 'employe' },
			{ label: 'Administrator', value: 'admin' },
			// { label: 'Author', value: 'author' },
			// { label: 'Editor', value: 'editor' },
			// { label: 'Maintainer', value: 'maintainer' },
			// { label: 'Subscriber', value: 'subscriber' },
		]

		const planOptions = [
			// { label: 'Basic', value: 'basic' },
			{ label: 'Firma', value: 'company' },
			// { label: 'Enterprise', value: 'enterprise' },
			// { label: 'Team', value: 'team' },
		]

		const statusOptions = [
			// { label: 'Pending', value: 'pending' },
			{ label: 'Aktiv', value: 'active' },
			{ label: 'Inaktiv', value: 'inactive' },
		]

		const findRoleLabel = (role) => {
			return roleOptions.find((o) => o.value === role).label
		}

		const findStatusLabel = (status) => {
			return statusOptions.find((o) => o.value === status).label
		}

		const {
			fetchUsers,
			deleteUser,
			tableColumns,
			perPage,
			currentPage,
			totalUsers,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refUserListTable,
			refetchData,

			// UI
			resolveUserRoleVariant,
			resolveUserRoleIcon,
			resolveUserStatusVariant,

			// Extra Filters
			roleFilter,
			planFilter,
			statusFilter,
		} = useUsersList()

		return {
			// Sidebar
			isAddNewUserSidebarActive,

			fetchUsers,
			deleteUser,
			tableColumns,
			perPage,
			currentPage,
			totalUsers,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refUserListTable,
			refetchData,

			// Filter
			avatarText,

			// UI
			resolveUserRoleVariant,
			resolveUserRoleIcon,
			resolveUserStatusVariant,
			findRoleLabel,
			findStatusLabel,

			roleOptions,
			planOptions,
			statusOptions,

			// Extra Filters
			roleFilter,
			planFilter,
			statusFilter,
		}
	},
}
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}

.table {
	min-height: 300px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
